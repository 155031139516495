import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { flashActions } from '../data-management/flashSlice';
import { useToken } from '../hooks/useToken';
import { Button } from '@material-ui/core';
import axios from 'axios';
import 'react-quill/dist/quill.snow.css'; // ES6

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export const Translations = () => {

  const [file, setFile] = useState<FileList | null>(null);
  const classes = useStyles();

  const dispatch = useDispatch();
  const token = useToken();

  const handleDownload = async () => {
    axios.post(`${process.env.REACT_APP_SERVER_URL}/settings/download-translation-csv`, {}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "translations.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }).catch((err) => {
      dispatch(flashActions.addFlashMessage("Hiba a feltöltés során: " + err))
    });
  };

  const handleUpload = async (e: any) => {
    e.preventDefault();
    if (!file) return;
    
    const formData = new FormData();
    formData.append("csv_file", file[0]);
    console.log(file);
    
    axios.post(`${process.env.REACT_APP_SERVER_URL}/settings/upload-translation-csv`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        ContentType: "multipart/form-data" 
      },
    }).then(() => {
      dispatch(flashActions.addFlashMessage("Nyelvi változók módosítva!"));
    }).catch((err) => {
      dispatch(flashActions.addFlashMessage("Hiba a feltöltés során: " + err))
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.flex}>
        <Button type="submit" variant="contained" color="primary" onClick={() => handleDownload()}>
          Fordítások letöltése CSV formátumban
        </Button>
        <form onSubmit={handleUpload}>
          <input type="file" accept=".csv" onChange={(e) => setFile(e?.target?.files)} />
          <Button type="submit" variant="contained" color="primary">
            Fordítások CSV adatainak visszatöltése  
          </Button>
        </form>
      </div>
    </div>
  );
};

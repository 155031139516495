import React, { FormEventHandler, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Box } from '@material-ui/core';
import { SettingsType, useSettingsGet } from '../hooks/useSettingsGet';
import { useSettingsSet } from '../hooks/useSettingsSet';
import { useDispatch } from 'react-redux';
import { flashActions } from '../data-management/flashSlice';
import { useToken } from '../hooks/useToken';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import 'react-quill/dist/quill.snow.css'; // ES6
const ReactQuill = require('react-quill');

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  gap: {
    marginTop: theme.spacing(2),
    textAlign: 'end',
  },
}));

export const TermsPage = () => {

  const [data, setData] = useState<any>("");

  const classes = useStyles();

  const settingsGetApi = useSettingsGet();
  const settingsSetApi = useSettingsSet();
  const dispatch = useDispatch();
  const token = useToken();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    settingsGetApi().then((settings) => {
      if(settings.can_edit_order_item){
        const terms_text = i18n.language == "en" ? settings.terms_en.value : settings.terms.value
        setData(terms_text);
      }
    });
  }, []);

  const handleSubmit: FormEventHandler<Element> = (e) => {
    e.preventDefault();
    const key = i18n.language == "en" ? "terms_en" : "terms";
    settingsSetApi({[key]: {value: data, type: "string", label: "Általános szerződési feltételek"}}).then(() => {
      dispatch(flashActions.addFlashMessage('Beállítások mentése sikeres!'))
    }).catch(() => {
      dispatch(flashActions.addFlashMessage('Beállítások mentése sikertelen!'))
    });
  };
  
  const reconfirmCall = function() {
    axios.post(`${process.env.REACT_APP_SERVER_URL}/settings/term-reconfirm`, {}, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then(() => {
      dispatch(flashActions.addFlashMessage('Sikeres elfogadtatási kérelem!'))
    })
  }

  return (
    <div className={classes.root}>
      <form onSubmit={handleSubmit}>
      <Box className={classes.gap}>
        <Button type="submit" variant="contained" color="primary">
          Mentés
        </Button>
        <Button onClick={reconfirmCall} variant="contained" color="primary" style={{marginLeft: "10px"}}>
          Szerződések újraelfogadtatása
        </Button>
      </Box>
      <ReactQuill value={data || ''} onChange={(changed : any) => {setData(changed)}} />
      </form>
    </div>
  );
};

import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LogoWhite from '../../../images/logo-light.png';
import { Link, NavLink } from 'react-router-dom';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Collapse, Drawer, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { authActions } from '../../data-management/authSlice';
import { useIsAdmin } from '../../hooks/useIsAdmin';
import { useIsUserManager } from '../../hooks/useIsUserManager';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  menuLogo: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(6),
    paddingLeft: theme.spacing(3),
  },
  list: {
    width: 250,
  },
  listItem: {
    marginTop: theme.spacing(3),
  },
  listItemText: {
    color: '#fff',
    textDecoration: 'none',
    fontSize: 18,
    display: 'block',
    width: '100%',
    padding: '10px 10px 10px 20px',
  },
  listSubitemText: {
    color: '#fff',
    textDecoration: 'none',
    fontSize: 16,
    display: 'block',
    width: '100%',
    padding: '10px 10px 10px 35px',
  },
  fullList: {
    width: 'auto',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  activeMenu: {
    backgroundColor: '#B2A77D',
  },
  '@global': {
    '.header .MuiListItem-root': {
      padding: 0,
    },
  },
}));

type HeaderProps = {
  open: boolean
}

export const Header: FC<HeaderProps> = ({ open }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openAdmin, setOpenAdmin] = React.useState(false);
  const dispatch = useDispatch();
  const isAdmin = useIsAdmin();
  const isUserManager = useIsUserManager();


  const handleClick = () => {
    setOpenAdmin(!openAdmin);
  };

  const onLogout = () => {
    dispatch(authActions.signOut());
  };

  return (
    <nav className={`header ${classes.drawer}`}>
      <Drawer anchor="left" open={open} variant="persistent">
        <div className={classes.list}>
          <List>
            <ListItem>
              <img src={LogoWhite} className={classes.menuLogo} alt="Nettfront" />
            </ListItem>
            <ListItem className={classes.listItem} button>
              <NavLink activeClassName={classes.activeMenu} className={classes.listItemText} to="/order">
                {t('header_menu_order')}
              </NavLink>
            </ListItem>
            <ListItem className={classes.listItem} button>
              <NavLink activeClassName={classes.activeMenu} className={classes.listItemText} to="/profil">
                {t('header_menu_profile')}
              </NavLink>
            </ListItem>
            <ListItem className={classes.listItem} button>
              <NavLink
                activeClassName={classes.activeMenu}
                className={classes.listItemText}
                to="/kapcsolat"
              >
                {t('header_menu_contact')}
              </NavLink>
            </ListItem>
            <ListItem className={classes.listItem} button>
              <NavLink
                activeClassName={classes.activeMenu}
                className={classes.listItemText}
                to="/saved-orders"
              >
                {t('header_menu_offers')}
              </NavLink>
            </ListItem>
            <ListItem className={classes.listItem} button>
              <NavLink
                activeClassName={classes.activeMenu}
                className={classes.listItemText}
                to="/order-history"
              >
                {t('header_menu_history')}
              </NavLink>
            </ListItem>
            <ListItem className={classes.listItem} button>
              <Typography className={classes.listItemText} onClick={onLogout}>
                {t('header_menu_sign_out')}
              </Typography>
            </ListItem>
            {
              isAdmin || isUserManager ?
                <>
                  <Box mt={2} />
                  <Divider
                    light
                    variant={'middle'}
                  />
                  <ListItem
                    classes={{
                      selected: classes.activeMenu,
                    }}
                    className={classes.listItem}
                    button
                    onClick={handleClick}
                  >
                    <ListItemText className={classes.listItemText} disableTypography primary="Admin" />
                    {openAdmin ? (
                      <ExpandLess style={{ color: '#fff' }} />
                    ) : (
                      <ExpandMore style={{ color: '#fff' }} />
                    )}
                  </ListItem>
                  <Collapse in={openAdmin} timeout="auto">
                    <List component="div" disablePadding>
                      <ListItem button className={classes.nested}>
                        <Link className={classes.listSubitemText} to="/users">
                          Felhasználó kezelő
                        </Link>
                      </ListItem>
                      { isAdmin ?
                      <>
                        <ListItem button className={classes.nested}>
                          <Link className={classes.listSubitemText} to="/size-list">
                            Méretszabályok
                          </Link>
                        </ListItem>
                        <ListItem button className={classes.nested}>
                          <Link className={classes.listSubitemText} to="/roles">
                            Szerepkör lista
                          </Link>
                        </ListItem>
                        <ListItem button className={classes.nested}>
                          <Link className={classes.listSubitemText} to="/sync">
                            Szinkronizálás
                          </Link>
                        </ListItem>
                        <ListItem button className={classes.nested}>
                          <Link className={classes.listSubitemText} to="/settings">
                            Beállítások
                          </Link>
                        </ListItem>
                        <ListItem button className={classes.nested}>
                          <Link className={classes.listSubitemText} to="/terms">
                            Szerződési feltételek
                          </Link>
                        </ListItem>
                        <ListItem button className={classes.nested}>
                          <Link className={classes.listSubitemText} to="/privacy">
                            Adatkezelési nyilatkozat
                          </Link>
                        </ListItem>
                        <ListItem button className={classes.nested}>
                          <Link className={classes.listSubitemText} to="/translations">
                            Fordítások
                          </Link>
                        </ListItem>
                        <ListItem button className={classes.nested}>
                          <Link className={classes.listSubitemText} to="/statistic">
                            Kimutatás
                          </Link>
                        </ListItem>
                        <ListItem button className={classes.nested}>
                          <Link className={classes.listSubitemText} to="/newsletter-patterns">
                            Hírlevél sablonok
                          </Link>
                        </ListItem>
                        <ListItem button className={classes.nested}>
                          <Link className={classes.listSubitemText} to="/newsletter-groups">
                            Hírlevél csoportok
                          </Link>
                        </ListItem>
                        <ListItem button className={classes.nested}>
                          <Link className={classes.listSubitemText} to="/newsletter-send">
                            Hírlevél küldés
                          </Link>
                        </ListItem>
                      </>
                      : <></> }
                    </List>
                  </Collapse>
                </> : <></>
            }
          </List>
        </div>
      </Drawer>
    </nav>
  );
};

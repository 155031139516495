import axios, { CancelTokenSource } from 'axios';
import { useAddresses } from './useAddresses';
import { useToken } from './useToken';
import { useProduct } from './useProduct';
import { useCategoryId } from './useCategoryId';
import { productActions, ValidationState } from '../data-management/productsSlice';
import { batch, useDispatch } from 'react-redux';
import { productSizeRuleHelper } from './useProductSizeRuleValid';
import { useProductSizeHelper } from './useProductSizeHelper';
import { useTranslation } from 'react-i18next';

export type ValidationOption = {
  FeatureCode: string
  OptionCode: string
  FeatureName: string
  OtherOptions: {
    code: string
    description: string
  }[]
}

export type SuggestionOption = {
  FeatureCode: string
  OptionCode: string
  FeatureName: string
}

type Response = {
  options: ValidationOption[]
  suggestions: SuggestionOption[]
  Extrak: any[]
  Sizerules: {
    min_h: number
    max_h: number
    min_w: number
    max_w: number
    ruleset_id: number
    required_extras: {
      [name: string]: string
    }[] | {
      [name: string]: string
    }
  }[]
}

type UseProductValidatorHookType = (productId: number) => (
  cancelToken: CancelTokenSource,
) => Promise<void>

export const useProductValidator: UseProductValidatorHookType = (productId) => {
  const { t } = useTranslation();
  const addresses = useAddresses();
  const firstShippingId = addresses?.find(({ type }) => type === 'SHIP')?.id ?? 0;
  const { catId } = useCategoryId();
  const dispatch = useDispatch()
  const token = useToken();
  const product = useProduct(productId);
  const {
    sizeHelperHeightArray,
    sizeHelperWidthArray
  } = useProductSizeHelper(productId)

  return async (cancelToken) => {
    dispatch(productActions.modifyProductValidationState({
      id: productId,
      validationState: 'processing'
    }))

    const sizeRules = productSizeRuleHelper(
      product.validationState,
      product.sizeRules,
      product.sizeRulesFromValidation
    )

    const singleSizes = sizeRules?.filter(({ height, width }) => {
      const same = height.max === height.min && width.min === width.max;
      return same;
    });

    const features = product.features.map((feature) => ({...feature}))

    const heightFeature = features.find(({ code }) => code === 'MAG');
    const widthFeature = features.find(({ code }) => code === 'SZEL');

    if (Array.isArray(singleSizes) && singleSizes.length === 1) {
      //Ugyanaz a min és max itt már
      if (heightFeature) {
        heightFeature.keyed_value = singleSizes[0].height.min.toString()
      }

      if (widthFeature) {
        widthFeature.keyed_value = singleSizes[0].width.min.toString()
      }
    }

    //Akkor nem validálunk ha elbukik az összes vagylagos mérethatáron
    let outOfSizeArrayHeight = 0;
    let outOfSizeArrayWidth = 0;
    if(product?.sizeRules && product?.sizeRules.length > 0){
      sizeHelperHeightArray.forEach(([min, max]) => {
        if(heightFeature?.keyed_value) {
          console.log(heightFeature?.keyed_value);
          if(min > heightFeature?.keyed_value || max < heightFeature?.keyed_value) {
            outOfSizeArrayHeight++;
          }
        }
      });
      sizeHelperWidthArray.forEach(([min, max]) => {
        if(widthFeature?.keyed_value) {
          if(min > widthFeature?.keyed_value || max < widthFeature?.keyed_value) {
            outOfSizeArrayWidth++;
          }
        }
      });
    }
    
    if(sizeHelperHeightArray.length != 0 && sizeHelperWidthArray.length != 0 && (outOfSizeArrayHeight == sizeHelperHeightArray.length || outOfSizeArrayWidth == sizeHelperWidthArray.length)) {
      return;
    }

    try {
      const { data: response } = await axios.post<Response>(
        `${process.env.REACT_APP_SERVER_URL}/validate`,
        {
          category_id: catId,
          ship_id: firstShippingId,
          items: [
            {
              product_id: product.product_id,
              quantity: product.quantity ?? 1,
              features: features,
              note: product.note ?? '',
            },
          ],
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          cancelToken: cancelToken?.token,
        },
      );

      let description = ''
      let errorFeatureCode = ''
      let errorFeatureName = ''

      const error = response.options.some((
        {
          FeatureCode,
          OptionCode,
          OtherOptions,
          FeatureName
        }) => {
        const feature = product.features.find(({ code }) => code === FeatureCode);

        console.log(FeatureCode);
        if (!feature) {
          return false;
        }

        const mismatch = feature.option_code !== OptionCode;

        if (mismatch) {
          description = OtherOptions.find(({ code }) => code === feature.option_code)?.description ?? '';
          errorFeatureName = FeatureName
          errorFeatureCode = FeatureCode
        }

        return mismatch;
      });

      response.suggestions.forEach(({
        FeatureCode, 
        FeatureName,
      }) => {
        if(FeatureCode == "MINTA_TIP"){
          dispatch(productActions.modifyProductMintaTipById({
            id: productId,
            mintaTip: FeatureName
          }));
        }
      });

      batch(() => {
        let errorState : ValidationState = error ? 'failed' : 'correct';
        let errorObject = error ? ({
          field: errorFeatureCode,
          text: t('use_product_validator_option_not_available', { errorFeatureName: errorFeatureName, description: description }),
        }) : undefined;

        //NF-513 - 2OLD Választása esetén a SZIN2 is kötelező
        const BORITAS = features.find(({ code }) => code === 'BORITAS')?.option_code;
        const SZIN2 = features.find(({ code }) => code === 'SZIN2')?.option_code;
        if(BORITAS == "2OLD" && !SZIN2){
          errorState = 'failed';
          errorObject = {
            field: "SZIN2",
            text: t('use_product_validator_choose_color_for_two_side'),
          };
        }

        dispatch(productActions.modifyProductValidationState({
          id: productId,
          validationState: errorState,
          error: errorObject,
        }))
        dispatch(productActions.modifyProductExtrasOptions({
          id: productId,
          options: response.options
        }));
        dispatch(productActions.modifyProductExtraCountById({
          id: productId,
          extraCount: response.Extrak?.length
        }));
        dispatch(productActions.modifyProductSizeRules({
          id: productId,
          type: 'validation',
          sizeRules: response.Sizerules.map(({min_h, max_h, min_w, max_w}) => ({
            width: {
              min: min_w,
              max: max_w
            },
            height: {
              min: min_h,
              max: max_h
            }
          })),
        }));
      })
    } catch (e: any) {
      batch(() => {
        if (e.constructor.name == 'Cancel') {
          dispatch(productActions.modifyProductValidationState({
            id: productId,
            validationState: 'processing',
            error: typeof e.response?.data === 'string' ? {
              text: e.response.data
            } : undefined
          }))
        }else{
          dispatch(productActions.modifyProductValidationState({
            id: productId,
            validationState: 'failed',
            error: typeof e.response?.data === 'string' ? {
              text: e.response.data
            } : undefined
          }))
        }
        
        dispatch(productActions.modifyProductSizeRules({
          id: productId,
          type: 'validation',
          sizeRules: undefined,
        }))
      })
    }
  };
};

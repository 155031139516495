import React, { FC, useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import { parseISO } from 'date-fns';
import { HideOnPrint } from '../HideOnPrint';
import { useToken } from '../../hooks/useToken';
import PrintIcon from '@material-ui/icons/Print';
import { SaveOrderDialog } from './SaveOrderDialog';
import { useIsAdmin } from '../../hooks/useIsAdmin';
import { useDebugXml } from '../../hooks/useDebugXML';
import { AskPricePrintModal } from './AskPricePrintModal';
import { RootState } from '../../data-management/store';
import { Address, authActions, AuthState } from '../../data-management/authSlice';
import { useCategoryId } from '../../hooks/useCategoryId';
import { OrderHistoryItem } from '../../hooks/useOrderHistory';
import { flashActions } from '../../data-management/flashSlice';
import { useShippingModes } from '../../hooks/useShippingModes';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';
import { orderActions, OrderItem, OrderState } from '../../data-management/orderSlice';
import { InputProps as StandardInputProps } from '@material-ui/core/Input/Input';
import { useSelectedOrganisationId } from '../../hooks/useSelectedOrganisationId';
import { useIsListTypeOrganization } from '../../hooks/useIsListTypeOrganization';
import { useIsZeroTypeOrganization } from '../../hooks/useIsZeroTypeOrganization';
import { useShippingAddressGetter } from '../../hooks/useShippingAddressGetter';
import { useSettingsGet } from '../../hooks/useSettingsGet';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(10),
  },
  infoList: {
    display: 'flex',
    flexDirection: 'column',
  },
  infoItem: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
  },
  infoSum: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(5),
  },
  infoTitle: {
    flexGrow: 1,
    minWidth: '250px',
    fontWeight: 'bold',
  },
  printText: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontSize: 16,
    fontWeight: 'bold',
    color: '#B2A77D',
    marginLeft: '5px',
  },
}));

export const OrderInfo: FC = () => {
  const classes = useStyles();
  const settingsGetApi = useSettingsGet();
  const { t, i18n } = useTranslation();

  const fromSavedOrder = useSelector<RootState, boolean>((state) => state.order.from_saved_order);
  const noteInitial = useSelector<RootState, string>((state) => state.order.note);
  const [note, setNote] = React.useState({
    content: noteInitial,
    currentCharCount: 0,
    limit: 1000,
  });

  const shippingModes = useShippingModes();
  const shipModeInitial = useSelector<RootState, string>((state) => state.order.ship_mode);
  const [shipMode, setShipMode] = React.useState<string | number>(''); //DO NOT CHANGE DEFAULT VAL
  
  const purchaseNumberInitial = useSelector<RootState, string>((state) => state.order.purchase_number);
  const [purchaseNumber, setPurchaseNumber] = useState(purchaseNumberInitial);

  const [termsAccepted, setTermsAccepted] = useState(false);
  const [termsDescription, setTermsDescription] = useState(t('order_info_terms_description'));

  const [extractShip, setExtractShip] = React.useState(false);
  const token = useToken();
  const user = useSelector<RootState, AuthState['user']>(
    (state) => state.auth.user,
  );
  const dispatch = useDispatch();
  const [addresses, setAddresses] = React.useState<Address[]>([]);
  
  const shipIdInitial = useSelector<RootState, number | undefined>((state) => state.order.ship_id) ?? "";
  const [selectedShippingAddressId, setSelectedShippingAddressId] = useState<string | number >('');

  const [askPriceModal, setAskPriceModal] = useState<any | undefined>(undefined);

  const browserHistory = useHistory();
  const isAdmin = useIsAdmin();
  const debugXML = useDebugXml();
  const shippingAddressGetter = useShippingAddressGetter();
  const [orderSaveDialog, setOrderSaveDialog] = useState(false);
  const orgId = useSelectedOrganisationId();
  
  const isListType = useIsListTypeOrganization();
  const isZeroType = useIsZeroTypeOrganization();
  const price_option_from_arkat = isZeroType ? "my_price" : "list_price";

  const { catId } = useCategoryId();
  const shouldSelectAddress = shipMode === 'TURA';
  const orderItems = useSelector<RootState, OrderItem[]>(
    (state) => state.order.orders,
  );
  
  const empty = !orderItems || orderItems.length === 0;
  const [cartData, setCartData] = useState<OrderHistoryItem | undefined>(
    undefined,
  );
  const [disableButton, setDisableButton] = useState(false);

  //Unique
  const differentWorkDays = orderItems.map(orderItem => orderItem.nettdata?.SzallMunkanap).filter((v,i,a)=>a.indexOf(v)==i);
  const [onDifferentDays, setOnDifferentDays] = useState<number | string>(0);
  
  useEffect(() => {
    setSelectedShippingAddressId('');
    setShipMode('');
  }, [orgId]);

  useEffect(() => {
    setAddresses([]);
    shippingAddressGetter().then((data) => {
      setAddresses(data);
      setSelectedShippingAddressId(shipIdInitial ? shipIdInitial : '');
    })
  }, [orgId]);
  
  useEffect(() => {
    settingsGetApi().then((settings : any) => {
      if(settings.before_send_order_text){
        setTermsDescription(i18n.language == "en" ? settings.before_send_order_text_en.value : settings.before_send_order_text.value);
      }
    });
    setShipMode(shipModeInitial ? shipModeInitial : '');
  }, [i18n.language]);

  useEffect(() => {
    if (shipMode === '' && shippingModes.length !== 0) {
      setShipMode(shipModeInitial ? shipModeInitial : '');
    }
  }, [shippingModes]);

  useEffect(() => {
    axios
      .get<OrderHistoryItem>(
        `${process.env.REACT_APP_SERVER_URL}/cart/details/${catId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(({ data }) => {
        setCartData(data);
        // Ez felülírja ha ajánlatot vagy megrendelést tölt be
        if(!fromSavedOrder){
          setPurchaseNumber(data.purchase_number ?? "");
          setNote({
            content: data.note ?? "",
            currentCharCount: 0,
            limit: 1000,
          });

          if(data.ship_mode){
            setShipMode(data.ship_mode);
          }
          if(data.ship_id){
            setSelectedShippingAddressId(data.ship_id);
          }
        }
      });
  }, []);

  const handleNoteChange: StandardInputProps['onChange'] = (e) => {
    setNote({
      ...note,
      currentCharCount: e.target.value.length,
      content: e.target.value,
    });
  };

  const onHandleChange: StandardInputProps['onChange'] = (e) =>
    setPurchaseNumber(e.target.value);

  const handleShipChange: SelectInputProps['onChange'] = (e) =>
    setShipMode(e.target.value as string);

  const handleChangeExtract = () => setExtractShip(!extractShip);

  const calcNettoPrice = () => {
    return (orderItems ?? []).reduce((acc, item) => {
      return (
        acc +
        Math.round(Number(item.nettdata.Price) * Number(item.nettdata.Qty))
      );
    }, 0);
  };

  const calcBruttoPrice = () => {
    return (orderItems ?? []).reduce((acc, item) => {
      return (
        acc + Number(item.nettdata.PriceBrutto) * Number(item.nettdata.Qty)
      );
    }, 0);
  };

  const calcItemsArea = () => {
    let area = 0;
    orderItems?.forEach(function (item) {
      area += parseFloat(item.nettdata.Meret);
    });

    return area.toFixed(2);
  };

  const onOrder = () => {
    if (!shipMode) {
      dispatch(
        flashActions.addErrorMessage(
          t('order_info_shipping_required'),
        ),
      );
      return;
    }
    if (shipMode === 'TURA' && !selectedShippingAddressId) {
      dispatch(
        flashActions.addErrorMessage(
          t('order_info_shipping_address_required'),
        ),
      );
    } else {
      setDisableButton(true);

      const shippingId =
        Number(selectedShippingAddressId) ??
        user?.shippingaddresses?.find(({ type }) => type === 'SHIP')?.id ??
        0;

      axios
        .post(
          `${process.env.REACT_APP_SERVER_URL}/order`,
          {
            note: note?.content ?? '',
            ship_id: shouldSelectAddress ? shippingId : undefined,
            ship_mode: shipMode,
            purchase_number: purchaseNumber,
            ship_breakdown: extractShip,
            group_by_work_days: onDifferentDays,
            org_id: orgId,
            category_id: catId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then((res) => {
          dispatch(orderActions.resetOrderItems());
          dispatch(flashActions.addFlashMessage(t('order_info_order_placed')));
          setPurchaseNumber('');
          setDisableButton(false);
          setNote({
            content: '',
            currentCharCount: 0,
            limit: 1000,
          });
        })
        .catch((error) => {
          const errorMsg = error.response.data;
          dispatch(flashActions.addFlashMessage(errorMsg.message));
          setDisableButton(false);
        });
    }
  };

  const shippingDate = useMemo(
    () =>
      orderItems.reduce(
        (highest, item) =>
          parseISO(item.nettdata.SzallMunkanapDate) > highest
            ? parseISO(item.nettdata.SzallMunkanapDate)
            : highest,
        0,
      ),
    [orderItems],
  );

  const openOrderSaveDialog = () => {
    setOrderSaveDialog(true);
  };

  const closeOrderSaveDialog = () => {
    setOrderSaveDialog(false);
  };

  return (
    <div className={classes.root}>
      <Container>
        <Grid container spacing={8}>
          <Grid item xs={6}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                height: '100%',
              }}
            >
              <Typography variant="body1">
                {t('order_info_order_text_1')}
                <br />
                <br /> <b><span style={{color: "red"}}>{t('order_info_order_text_2')}</span></b>
                <br />
                <br />{t('order_info_order_text_3')}
                <br />
                <br />{t('order_info_order_text_4')} 1m<sup style={{fontSize: '11px'}}>2</sup>. {t('order_info_order_text_5')} 1m<sup style={{fontSize: '11px'}}>2</sup> {t('order_info_order_text_6')}
                <br />
                <br />{t('order_info_order_text_7')}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.infoList}>
              <div className={classes.infoItem}>
                <Typography variant="body1" className={classes.infoTitle}>
                  {t('order_info_product_net_price_all')}
                </Typography>
                <Typography variant="body1">
                  {calcNettoPrice().toLocaleString('hu', {
                    style: 'currency',
                    currency: 'HUF',
                    maximumFractionDigits: 0,
                  })}
                </Typography>
              </div>
              <div className={classes.infoItem}>
                <Typography variant="body1" className={classes.infoTitle}>
                  {t('order_info_product_gross_price_all')}
                </Typography>
                <Typography variant="body1">
                  {Math.round(calcBruttoPrice()).toLocaleString('hu', {
                    style: 'currency',
                    currency: 'HUF',
                    maximumFractionDigits: 0,
                  })}
                </Typography>
              </div>
              <div className={classes.infoItem}>
                <Typography variant="body1" className={classes.infoTitle}>
                  {t('order_info_product_positions_count')}
                </Typography>
                <Typography variant="body1">
                  {/* Kitöltetlen order item van az elején */}
                  {orderItems ? orderItems.length : 0} {t('order_info_count_suffix')}
                </Typography>
              </div>
              <div className={classes.infoItem}>
                <Typography variant="body1" className={classes.infoTitle}>
                  {t('order_info_sub_products_count')}
                </Typography>
                <Typography variant="body1">
                  {orderItems?.reduce(
                    (acc, { quantity }) => acc + (quantity ?? 0),
                    0,
                  ) ?? 0}{' '}
                  {t('order_info_count_suffix')}
                </Typography>
              </div>
              <div className={classes.infoItem}>
                <Typography variant="body1" className={classes.infoTitle}>
                  {t('order_info_face_of_pieces')}
                </Typography>
                <Typography variant="body1">{calcItemsArea()} m2</Typography>
              </div>
              <div className={classes.infoItem}>
                <Typography variant="body1" className={classes.infoTitle}>
                  {t('order_info_self_identification')}
                </Typography>
                <TextField
                  fullWidth
                  name="purchaseNumber"
                  value={purchaseNumber}
                  onChange={onHandleChange}
                  variant="standard"
                />
              </div>
              <div className={classes.infoItem}>
                <Typography variant="body1" className={classes.infoTitle}>
                  {t('order_info_comment')}
                </Typography>
                <div style={{ width: '100%' }}>
                  <TextField
                    placeholder={t('order_info_write_comment')}
                    fullWidth
                    multiline
                    name="note"
                    value={note.content}
                    rows="6"
                    variant="outlined"
                    onChange={handleNoteChange}
                    inputProps={{
                      maxLength: note.limit,
                    }}
                  />
                  <div
                    style={{
                      color: '#535353',
                      textAlign: 'right',
                      marginTop: 10,
                    }}
                  >
                    {note.limit - note.currentCharCount} {t('order_info_characters_left')}
                  </div>
                </div>
              </div>
              <div style={{padding: "10px", background: "#B2A77D33"}}>
                <div className={classes.infoItem} style={{ marginTop: 20 }}>
                  <Typography variant="body1" className={classes.infoTitle}>
                    {t('order_info_shipping_mode')}
                  </Typography>
                  <div>
                    <Select
                      fullWidth
                      name="ship"
                      id="demo-simple-select"
                      style={{ display: 'block', minWidth: '200px' }}
                      value={shipMode}
                      onChange={handleShipChange}
                    >
                      {shippingModes.map(({ code, name }) => (
                        <MenuItem value={code} key={code}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
                {shouldSelectAddress ? (
                  <Box mt={0} mb={3}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        name="shippingAddress"
                        value={selectedShippingAddressId.toString()}
                        onChange={(event) => {
                          setSelectedShippingAddressId(event.target.value);
                        }}
                      >
                        {addresses
                          ?.filter(({ type }) => type === 'SHIP')
                          .map(({ id, address, city, zip, country }, index) => (
                            <FormControlLabel
                              key={index}
                              value={id.toString()}
                              control={<Radio />}
                              label={
                                <>
                                  <b>{index + 1}. {t('order_info_address_text')} </b>
                                  {zip} {city}, {address} - {country}
                                </>
                              }
                            />
                          ))}
                      </RadioGroup>
                    </FormControl>
                  </Box>
                ) : (
                  <></>
                )}
              </div>
              {differentWorkDays.length > 1 ? (
                <>
                <Typography variant="body1" className={classes.infoTitle}>
                  {t('order_info_work_time_info')}
                </Typography>
                <Box mt={3} mb={3}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      name="onDifferentDays"
                      value={onDifferentDays}
                      onChange={(event) => {
                        setOnDifferentDays(event.target.value);
                      }}
                    >
                    <FormControlLabel
                      key={0}
                      value={"oneblock"}
                      control={<Radio />}
                      label={<>{t('order_info_later_time_shipping')}</>}
                    />
                    <FormControlLabel
                      key={1}
                      value={"seperate"}
                      control={<Radio />}
                      label={<>{t('order_info_order_seperated_from_eachother')}</>}
                    />
                    </RadioGroup>
                  </FormControl>
                </Box>
                </>
              ) : (
                <></>
              )}
              <div className={classes.infoSum}>
                <Typography variant="body1" className={classes.infoTitle}>
                  {t('order_info_price_sum_of_all')}
                </Typography>
                <div
                  style={{
                    display: 'flex',
                    flexGrow: 1,
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                    {t('order_info_price_net_text')}{' '}
                    {calcNettoPrice().toLocaleString('hu', {
                      style: 'currency',
                      currency: 'HUF',
                      maximumFractionDigits: 0,
                    })}
                  </Typography>
                  <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                    {t('order_info_price_gross_text')}{' '}
                    {Math.round(calcBruttoPrice()).toLocaleString('hu', {
                      style: 'currency',
                      currency: 'HUF',
                      maximumFractionDigits: 0,
                    })}
                  </Typography>
                </div>
              </div>
              <HideOnPrint component={Box}>
                <div style={{ marginTop: 30 }}>
                  <button
                    className="custom-button-brown"
                    style={{ width: '100%' }}
                    onClick={openOrderSaveDialog}
                    disabled={empty || disableButton}
                  >
                    {t('order_info_save_order_button')}
                  </button>
                </div>
                <div style={{ marginTop: 20 }}>
                  <FormControlLabel control={
                    <Checkbox
                      checked={termsAccepted}
                      color="primary"
                      onChange={(e) => setTermsAccepted(e.target.checked)}
                      name="terms_accepted"
                    />
                    }
                    label={termsDescription}
                  />
                
                </div>
                <div style={{ marginTop: 30 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Button
                        fullWidth
                        onClick={() => {
                          isListType ? window.open(`/print/${cartData?.secure_id}?price_option=${price_option_from_arkat}&percentage=0`, '_blank', 'noopener,noreferrer') : setAskPriceModal(cartData?.secure_id);
                        }}
                      >
                        <PrintIcon style={{ fill: '#B2A77D' }}></PrintIcon>
                        <Typography className={classes.printText}>
                          {t('order_info_print_button')}
                        </Typography>
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <button
                        className="custom-button-brown"
                        style={{ width: '100%' }}
                        onClick={onOrder}
                        disabled={empty || disableButton || !termsAccepted}
                      >
                        {t('order_info_place_order_button')}
                      </button>
                    </Grid>
                  </Grid>
                </div>
                {isAdmin ? (
                  <div style={{ marginTop: 30 }}>
                    <button
                      className="custom-button-brown"
                      style={{ width: '100%' }}
                      onClick={() => {
                        const shippingId =
                          Number(selectedShippingAddressId) ??
                          user?.shippingaddresses?.find(
                            ({ type }) => type === 'SHIP',
                          )?.id ??
                          0;

                        debugXML({
                          note: note?.content ?? '',
                          ship_id: shouldSelectAddress ? shippingId : 0,
                          ship_mode: shipMode,
                          purchase_number: purchaseNumber,
                          ship_breakdown: extractShip,
                          group_by_work_days: onDifferentDays,
                          org_id: orgId,
                        }).catch((err) => console.log(err));
                      }}
                    >
                      {t('order_info_xml_button')}
                    </button>
                  </div>
                ) : (
                  <></>
                )}
              </HideOnPrint>
            </div>
          </Grid>
        </Grid>
      </Container>
      <AskPricePrintModal
        open={!!askPriceModal}
        url={`/print/${askPriceModal}`}
        onClose={() => {
          setAskPriceModal(undefined);
        }}
      />
      <SaveOrderDialog open={orderSaveDialog} onClose={closeOrderSaveDialog} shipId={selectedShippingAddressId} shipMode={shipMode} purchaseNumber={purchaseNumber} note={note.content} />
    </div>
  );
};

export default OrderInfo;
